import { writable } from 'svelte/store';

const isDesktop = writable(false);

export const calculateIsDesktop = () => {
	if (typeof window === 'undefined') return;
	isDesktop.set(window.innerWidth > 768);
};

export default isDesktop;
